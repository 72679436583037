<template>
  <Modal v-model="modalModifyServiceRule" :title="isAdd?'新增服务规则':'编辑服务规则'" :mask-closable="false" width="800">
    <Row class="m-b-20">
      <i-col span="12">
        <span class="title">运维公司：</span>
        <Select v-model="serviceRuleBean.companyId" size="small" style="width:200px" placeholder="请选择运维公司">
          <Option v-for="item in maintenanceCompanyList" :key="item.id" :value="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="m-b-20">
      <i-col span="12">
        <span class="title" style="margin-right: 24px;">费用：</span>
        <InputNumber
              :max="1000000"
              style="width:200px"
              size="small"
              v-model="serviceRuleBean.price"
              :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
      </i-col>
      <i-col span="12">
        <span class="title" style="margin-right: 35px;">单位：</span>
        <RadioGroup v-model="serviceRuleBean.unit">
          <Radio v-for="item in unitList" :key="item.value" :label="item.value">{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="m-b-20">
      <i-col span="12">
        <span class="title">开始时间：</span>
        <TimePicker type="time" size="small" v-model="serviceRuleBean.startTime" placeholder="设置开始时间" style="width: 200px"></TimePicker>
      </i-col>
      <i-col span="12">
        <span class="title" style="margin-right: 12px;">结束时间：</span>
        <TimePicker type="time" size="small" v-model="serviceRuleBean.endTime" placeholder="设置结束时间" style="width: 200px"></TimePicker>
      </i-col>
    </Row>
    <Row class="m-b-20">
      <i-col span="12">
        <span class="title">费用类型：</span>
        <RadioGroup v-model="serviceRuleBean.feeType">
          <Radio v-for="item in feeTypeArray" :key="item.value" :label="item.value">{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="12">
        <span class="title">质保有效期：</span>
        <InputNumber :min="0" :max="10000" size="small" v-model="serviceRuleBean.period"></InputNumber>
        <span class="m-l-5">天</span>
      </i-col>
    </Row>
     <Row class="m-b-20">
      <i-col span="12">
        <span class="title">运维资产：</span>
        <Select v-model="serviceRuleBean.assetId" size="small" style="width:200px" placeholder="请选择运维资产，非必填">
          <Option v-for="item in assetArray" :key="'assetId_'+item.id" :value="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="12">
        <span class="title">运维区域：</span>
        <Select v-model="serviceRuleBean.position" size="small" style="width:200px" placeholder="请选择运维区域">
          <Option v-for="(item,index) in positionArray" :key="index" :value="item.value">{{item.name}}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="m-b-20">
      <i-col span="12" v-if="costType===5">
        <span class="title">服务分组：</span>
        <Select v-model="serviceRuleBean.group" size="small" style="width:200px" placeholder="请选择服务分组">
          <Option v-for="item in serviceRuleGroups" :key="item.id" :value="item.id">{{item.name}}</Option>
        </Select>
        <a @click="handleAddServiceGroup" class="m-l-5"><Icon type="md-add" size="16"/>添加新的分组</a>
      </i-col>
    </Row>

    <div slot="footer" >
      <Button class="btn-black m-r-5" style="width:60px" size="small" @click="modalModifyServiceRule = false">取消</Button>
      <Button class="m-l-8" type="success" size="small" @click="handleSubmitServiceRule">{{isAdd?'新增':'保存'}}服务规则项</Button>
    </div>
  </Modal>
</template>

<script>
import em from '@/utils/enum'
import { getCompanyList } from '@/api/os/company'
import { getServiceRuleList, addServiceRule } from '@/api/msp/servicerule'
import { getChargeStatus } from '@/api/msp/status'
import { getPublisherAssetList } from '@/api/rim/asset'

export default {
  props: {
    companyId: [String, Number],
    publisherId: [String, Number]
  },
  data () {
    return {
      modalModifyServiceRule: false,
      isAdd: true, // 是否添加操作
      costType: 0,
      editIndex: 0,
      positionArray: em.positionEnum,
      unitList: [], // 计费单位
      feeTypeArray: em.maintenanceFeeTypeEnum,
      maintenanceCompanyList: [], // 运维公司清单

      serviceRuleBean: {
        companyId: null,
        companyName: '',
        price: 0,
        unit: 1,
        group: null,
        groupName: '',
        period: 365,
        feeType: 1,
        feeTypeName: '',
        startTime: '00:00:00',
        endTime: '23:59:59',
        position: null,
        positionName: '',
        assetId: -1,
        assetName: '',
        stationId: -1
      },
      newServiceGroup: '',
      serviceRuleGroups: [],
      assetArray: [],
      stationArray: []
    }
  },
  created () {
    this.loadMaintenanceCompanyList()
    this.loadServiceRuleGroupList()
    this.loadServiceUnit()
  },
  methods: {
    loadMaintenanceCompanyList () {
      const postData2 = {
        publisherId: this.publisherId,
        enabled: true,
        type: 3

      }
      getCompanyList(postData2).then(res => {
        this.maintenanceCompanyList = res
      })
    },
    loadServiceRuleGroupList () {
      const postData = {
        publisherId: this.publisherId,
        companyId: this.companyId
      }

      getServiceRuleList(postData).then(res => {
        this.serviceRuleGroups = res
      })
    },
    loadServiceUnit () {
      getChargeStatus().then(res => {
        this.unitList = res
      })
    },
    async getAssetData () {
      const result = await getPublisherAssetList() || []
      this.assetArray = [{ id: -1, name: '全线路' }, ...result]
    },
    showModal (isAdd, costType, editBean, editIndex) {
      this.isAdd = isAdd
      this.costType = costType
      this.editIndex = editIndex
      this.modalModifyServiceRule = true
      this.getAssetData()

      if (isAdd) {
        this.serviceRuleBean = {
          companyId: this.maintenanceCompanyList[0].id,
          companyName: '',
          price: 0,
          unit: 1,
          unitName: '',
          group: null,
          groupName: '',
          period: 365,
          feeType: 1,
          feeTypeName: '',
          startTime: '00:00:00',
          endTime: '23:59:59',
          position: this.positionArray[0].value,
          positionName: '',
          assetId: -1,
          assetName: '',
          stationId: -1
        }
      } else {
        this.serviceRuleBean = editBean
      }
    },
    handleSubmitServiceRule () {
      this.serviceRuleBean.companyName = this.maintenanceCompanyList.find(x => x.id === this.serviceRuleBean.companyId).name
      this.serviceRuleBean.feeTypeName = this.feeTypeArray.find(x => x.value === this.serviceRuleBean.feeType).name
      this.serviceRuleBean.unitName = this.unitList.find(x => x.value === this.serviceRuleBean.unit).name
      this.serviceRuleBean.positionName = this.positionArray.find(x => x.value === this.serviceRuleBean.position).name
      this.serviceRuleBean.assetName = this.assetArray.find(x => x.id === this.serviceRuleBean.assetId).name

      if (this.costType === 5 && this.serviceRuleBean.group && this.serviceRuleBean.group !== -1) {
        this.serviceRuleBean.groupName = this.serviceRuleGroups.find(x => x.id === this.serviceRuleBean.group).name
      }

      this.$emit('update-sevice-rule',
        {
          isAdd: this.isAdd,
          editIndex: this.editIndex,
          serviceRuleBean: this.serviceRuleBean
        }
      )

      this.modalModifyServiceRule = false
    },
    handleAddServiceGroup () {
      this.newServiceGroup = ''
      const self = this

      this.$Modal.confirm({
        render: (h) => {
          return h('Input', {
            props: {
              value: this.newServiceGroup,
              autofocus: true,
              placeholder: '请输入分组名称...'
            },
            on: {
              input: (val) => {
                this.newServiceGroup = val
              }
            }
          })
        },
        loading: true,
        onOk: () => {
          if (self.newServiceGroup.trim().length > 1) {
            const serviceRuleBean = {
              publisherId: self.publisherId,
              companyId: self.companyId,
              name: self.newServiceGroup
            }

            // 开始添加新的服务规则
            addServiceRule(serviceRuleBean).then(() => {
              self.loadServiceRuleList()
              self.$Notice.success({ desc: '规则分组添加成功' })
              self.$Modal.remove()
            })
          } else {
            self.$Notice.warning({ title: '请求异常', desc: '规则分组名称不能空！' })
            self.$Modal.remove()
          }
        }
      })

      document.getElementsByClassName('ivu-modal-confirm')[0].parentNode.parentNode.parentNode.style.cssText =
        'margin-top: 100px; width: 416px;'
    }
  }
}
</script>
