import request from '@/utils/requestV2'
const qs = require('qs')

// 添加综合费计费规则
export function addServiceRule (data) {
  return request({
    url: '/ooh-msp/v1/servicerule/addservicerule',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除配置服务项
export function getServiceRuleList (data) {
  return request({
    url: '/ooh-msp/v1/servicerule/getservicerulelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
